import { MdLock } from "react-icons/md";
import { HiOutlineLogout } from "react-icons/hi";
import { AiOutlineUserAdd } from "react-icons/ai";
import { IoChevronBack } from "react-icons/io5";
import { useContext } from "react";

import { Back, Container, Menu, Profile } from "./styles";
import theme from "../../styles/theme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useSignOut from "../../hooks/api/useSignOut";
import UserContext from "../../contexts/UserContext";
import toastUtils from "../../utils/toast-utils";

export function Header() {
  const path = useLocation().pathname;
  const disabled = path === "/" || path === "/restrito/historico";
  const navigate = useNavigate();
  const { signOut } = useSignOut();
  const { userData, setUserData } = useContext(UserContext);

  async function handleSignOut() {
    try {
      await signOut(userData.user.id);

      toastUtils.toaster({
        message: "Sessão encerrada!",
        type: toastUtils.type.default,
        position: toastUtils.position.topCenter,
        theme: { box: theme.COLORS.BLUE, progressBar: theme.COLORS.WHITE },
      });

      setUserData({});
      navigate("/");
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Back
        disabled={disabled}
        theme={theme}
        onClick={() =>
          path !== "/"
            ? navigate(-1)
            : () => {
                return;
              }
        }
      >
        {<IoChevronBack />}
      </Back>

      <Profile to={"/"} theme={theme}>
        <img
          src="https://salvage.com.br/wp-content/uploads/2022/08/logo-salvage.svg"
          alt="SALVAGE"
        />
        <div>
          <span> </span>
          <strong> </strong>
        </div>
      </Profile>

      <span className="restrictedAccess">
        {path.includes("restrito") ? (
          <Menu>
            <AiOutlineUserAdd onClick={() => navigate("/restrito/sign-up")} />
            <HiOutlineLogout onClick={handleSignOut} />
          </Menu>
        ) : (
          <>
            <MdLock />
            <Link to="/login">Acesso Restrito</Link>
          </>
        )}
      </span>
    </Container>
  );
}
