import styled from "styled-components";

export const Container = styled.div`
  display: ${({ deleting }) => (deleting ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 3;
  background-color: ${({ theme }) => theme.COLORS.GRAY_TRANSLUCENT};
  align-items: center;
  justify-content: center;

  .confirm,
  .cancel {
    position: static !important;
    display: flex !important;
    margin: 0 10px;
    height: 40px;
    font-size: 18px;
    width: auto;
  }

  h5 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px 0;
    color: ${({ theme }) => theme.COLORS.GRAY_300};
  }

  span {
    width: 240px;
    display: flex;
    justify-content: space-between;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  padding-left: 16px;
  margin: auto;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  position: relative;
`;

export const CloseButton = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: transparent;
  border: 0;
  top: 10px;
  right: 10px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  svg:hover {
    color: ${({ theme }) => theme.COLORS.WHITE};
  }
`;
