import theme from "../../styles/theme";
import { Container } from "./styles";

export default function Label({ id, text, value }) {
  return (
    <Container theme={theme} value={value}>
      <label htmlFor={id}>{text}</label>
    </Container>
  );
}
