import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useState } from "react";

import { Container, VisibilitySwitch } from "./styles";
import theme from "../../styles/theme";

export default function PasswordInput({ ...rest }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container theme={theme} disabled={rest.disabled}>
      <input type={showPassword ? "text" : "password"} {...rest} />
      <Visibility
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />
    </Container>
  );
}

export function Visibility({ showPassword, setShowPassword }) {
  return (
    <VisibilitySwitch
      theme={theme}
      type="button"
      onClick={() => setShowPassword(!showPassword)}
    >
      {showPassword ? (
        <MdOutlineVisibilityOff size={20} />
      ) : (
        <MdOutlineVisibility size={20} />
      )}
    </VisibilitySwitch>
  );
}
