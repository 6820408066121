import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Input from "../components/Input";
import PasswordInput from "../components/PasswordInput";
import Label from "../components/Label";
import theme from "../styles/theme";
import loginUtils from "../utils/login-utils";
import {
  AuthenticationWrapper,
  ButtonsWrapper,
  Container,
  Form,
} from "./styles";
import PasswordInstructions from "../components/PasswordInstructions";
import toastUtils from "../utils/toast-utils";
import useSignUp from "../hooks/api/useSignUp";

export default function SignUp() {
  const { signUp } = useSignUp();
  const [form, setForm] = useState(loginUtils.emptySignUpForm);
  const [typingPassword, setTypingPassword] = useState(false);
  const navigate = useNavigate();

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await signUp(form);

      navigate("/restrito/historico");

      toastUtils.toaster({
        message: "Usuário criado com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setForm({ ...form, password: "", confirmPassword: "" });
    }
  }

  return (
    <Container theme={theme}>
      <AuthenticationWrapper theme={theme}>
        <h1>CADASTRAR NOVO USUÁRIO</h1>
        <Form onSubmit={handleSubmit}>
          <span>
            <Label id="email" text="E-mail" value={form.email} />
            <Input
              id="email"
              placeholder="Digite o e-mail para cadastro"
              type="email"
              size="long"
              required
              disabled={false}
              value={form.email}
              onChange={(e) =>
                handleForm({
                  name: e.target.id,
                  value: e.target.value,
                })
              }
            />
          </span>

          <span className="password">
            <Label id="password" text="Senha" value={form.password} />
            <PasswordInput
              placeholder="Crie uma senha"
              name="password"
              value={form.password}
              required
              onFocus={() => setTypingPassword(true)}
              onBlur={() => setTypingPassword(false)}
              onChange={(e) =>
                handleForm({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              minLength="8"
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
            />

            <PasswordInstructions
              typingPassword={typingPassword}
              password={form.password}
            />
          </span>

          <span>
            <Label
              id="confirmPassword"
              text="Confirmação de senha"
              value={form.email}
            />
            <PasswordInput
              placeholder="Confirme a senha"
              name="confirmPassword"
              value={form.confirmPassword}
              required
              onChange={(e) =>
                handleForm({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              minLength="8"
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
            />
          </span>

          <ButtonsWrapper theme={theme}>
            <Button
              title="Cancelar"
              type="button"
              className="clear"
              onClick={() => navigate("/restrito/historico")}
            />
            <Button title="Cadastrar" />
          </ButtonsWrapper>
        </Form>
      </AuthenticationWrapper>
    </Container>
  );
}
