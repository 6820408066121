import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Input from "../components/Input";
import Label from "../components/Label";
import { ButtonsWrapper, Container, Form } from "./styles";
import theme from "../styles/theme";
import homeUtils from "../utils/home-utils";
import CheckBoxGroup from "../components/CheckBoxGroup";
import useScores from "../hooks/api/useScores";
import toastUtils from "../utils/toast-utils";
import Button from "../components/Button";
import useSurvey from "../hooks/api/useSurvey";
import surveyUtils from "../utils/survey-utils";

export default function HistorySurvey() {
  const { getScores } = useScores();
  const { getSurvey } = useSurvey();
  const { id } = useParams();
  const [form, setForm] = useState(homeUtils.emptyForm);
  const [scores, setScores] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    loadScores();
    loadSurveyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadScores() {
    try {
      const result = await getScores();

      if (result) setScores(result);
    } catch (error) {
      toastUtils.toaster({
        message: error.response?.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function loadSurveyData() {
    try {
      const result = await getSurvey(id);

      if (result) setForm(result);
    } catch (error) {
      toastUtils.toaster({
        message: error.response?.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <h1>FEEDBACK DO CLIENTE</h1>

      <h3>{`Enviado em ${surveyUtils.formatDate(form.createdAt)}`}</h3>

      <Form>
        <span>
          <Label id="name" text="Nome" value={form.name} />
          <Input
            id="name"
            placeholder="Resposta"
            type="text"
            size="short"
            disabled
            value={form.name}
            readOnly
          />
        </span>

        <span>
          <Label id="email" text="E-mail" value={form.email} />
          <Input
            id="email"
            placeholder="Resposta"
            type="email"
            size="short"
            required
            disabled
            value={form.email}
            readOnly
          />
        </span>

        <span>
          <Label
            id="quality"
            text={homeUtils.questions.quality.pt}
            value={form.quality}
          />
          <CheckBoxGroup
            list={scores}
            id="quality"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="professionalism"
            text={homeUtils.questions.professionalism.pt}
            value={form.professionalism.value}
          />
          <CheckBoxGroup
            list={scores}
            id="professionalism"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="interest"
            text={homeUtils.questions.interest.pt}
            value={form.interest}
          />
          <CheckBoxGroup
            list={scores}
            id="interest"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="relationship"
            text={homeUtils.questions.relationship.pt}
            value={form.relationship}
          />
          <CheckBoxGroup
            list={scores}
            id="relationship"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="policies"
            text={homeUtils.questions.policies.pt}
            value={form.policies}
          />
          <CheckBoxGroup
            list={scores}
            id="policies"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="proactivity"
            text={homeUtils.questions.proactivity.pt}
            value={form.proactivity}
          />
          <CheckBoxGroup
            list={scores}
            id="proactivity"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="ethic"
            text={homeUtils.questions.ethic.pt}
            value={form.ethic}
          />
          <CheckBoxGroup
            list={scores}
            id="ethic"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="recommendation"
            text={homeUtils.questions.recommendation.pt}
            value={form.recommendation}
          />
          <CheckBoxGroup
            list={scores}
            id="recommendation"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="punctuality"
            text={homeUtils.questions.punctuality.pt}
            value={form.punctuality}
          />
          <CheckBoxGroup
            list={scores}
            id="punctuality"
            form={form}
            disabled
            handleForm={() => {
              return;
            }}
          />
        </span>

        <span>
          <Label
            id="suggestions"
            text={homeUtils.questions.suggestions.pt}
            value={form.suggestions}
          />
          <Input
            id="suggestions"
            placeholder="Nenhuma resposta enviada."
            type="text"
            size="long"
            value={form.suggestions}
            readOnly
          />
        </span>

        <ButtonsWrapper theme={theme}>
          <Button
            title="Voltar"
            type="button"
            className="back"
            onClick={() => navigate("/restrito/historico")}
          />
        </ButtonsWrapper>
      </Form>
    </Container>
  );
}
