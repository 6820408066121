import useAsync from "../useAsync";

import * as surveyApi from "../../services/surveyApi";

export default function useSaveSurvey() {
  const {
    loading: saveSurveyLoading,
    error: saveSurveyError,
    act: saveSurvey,
  } = useAsync((data) => surveyApi.postSurvey(data), false);

  return {
    saveSurveyLoading,
    saveSurveyError,
    saveSurvey,
  };
}
