import useAsync from "../useAsync";

import * as surveyApi from "../../services/surveyApi";
import useToken from "../useToken";

export default function useSurvey() {
  const token = useToken();

  const {
    data: survey,
    loading: surveyLoading,
    error: surveyError,
    act: getSurvey,
  } = useAsync((data) => surveyApi.getSurveyData(data, token), false);

  return {
    survey,
    surveyLoading,
    surveyError,
    getSurvey,
  };
}
