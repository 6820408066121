import { useState } from "react";

import theme from "../styles/theme";
import { Container, HistoryOptions, OptionWrapper } from "./styles";
import HistoryList from "./HistoryList";
import HistoryResults from "./HistoryResults";

export default function History() {
  const [selected, setSelected] = useState("surveys");

  function handleSelection(value) {
    if (value !== selected) setSelected(value);
  }

  return (
    <Container theme={theme} selected={selected}>
      <HistoryOptions theme={theme}>
        <OptionWrapper
          theme={theme}
          selected={selected === "surveys"}
          onClick={() => handleSelection("surveys")}
        >
          Pesquisas
        </OptionWrapper>
        <OptionWrapper
          theme={theme}
          selected={selected === "charts"}
          onClick={() => handleSelection("charts")}
        >
          Gráficos
        </OptionWrapper>
      </HistoryOptions>

      {selected === "surveys" ? <HistoryList /> : <HistoryResults />}
    </Container>
  );
}
