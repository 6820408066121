import useAsync from "../useAsync";

import * as scoreApi from "../../services/scoreApi";

export default function useScores() {
  const {
    loading: scoresLoading,
    error: scoresError,
    act: getScores,
  } = useAsync(() => scoreApi.getScores(), false);

  return {
    scoresLoading,
    scoresError,
    getScores,
  };
}
