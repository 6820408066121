import theme from "../../styles/theme";
import { Container, Wrapper } from "./styles";
import Button from "../Button";
import toastUtils from "../../utils/toast-utils";

export default function DeleteItem({
  itemId,
  text,
  list,
  setList,
  index,
  deleting,
  setDeleting,
  action,
}) {
  async function deleteSurvey() {
    const result = [...list];

    if (itemId) {
      try {
        await action(itemId);
      } catch (error) {
        toastUtils.toaster({
          message: error.response.data,
          type: toastUtils.type.error,
          position: toastUtils.position.topCenter,
          theme: toastUtils.theme.colored,
        });

        return;
      }
    }

    result.splice(index, 1);

    setList(result);
    setDeleting(false);
  }

  return (
    <Container theme={theme} deleting={deleting}>
      <Wrapper theme={theme}>
        <h5>{text}</h5>

        <span>
          <Button
            className="cancel"
            title="Cancelar"
            type="button"
            onClick={() => setDeleting(false)}
          />

          <Button
            className="confirm"
            title="Confirmar"
            editing={true}
            type="button"
            onClick={deleteSurvey}
          />
        </span>
      </Wrapper>
    </Container>
  );
}
