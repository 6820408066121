import api from "./api";

export async function signUp({ email, password, confirmPassword }, token) {
  const response = await api.post(
    "/auth/sign-up",
    {
      email,
      password,
      confirmPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
}

export async function signIn({ email, password }) {
  const response = await api.post("/auth/sign-in", {
    email,
    password,
  });
  return response.data;
}

export async function signOut(userId) {
  const response = await api.put(`/auth/sign-out/${userId}`);
  return response.data;
}

export async function sendPasswordChangeEmail(email) {
  const response = await api.get(`/auth/change-password?email=${email}`);
  return response.data;
}

export async function changePasssword({
  userId,
  key,
  password,
  confirmPassword,
}) {
  const response = await api.put(`/auth/change-password/${userId}`, {
    key,
    password,
    confirmPassword,
  });
  return response.data;
}
