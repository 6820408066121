import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CgReorder } from "react-icons/cg";
import { IoChevronForward } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";

import { Edit, ListItem, ParamsWrapper } from "./styles";
import useSurveys from "../hooks/api/useSurveys";
import toastUtils from "../utils/toast-utils";
import Select from "../components/Select";
import surveyUtils from "../utils/survey-utils";
import Loader from "../components/Loader";
import ListWrapper from "../components/ListWrapper";
import theme from "../styles/theme";
import useDeleteSurvey from "../hooks/api/useDeleteSurvey";
import DeleteItem from "../components/DeleteItem";
import Period from "../components/Period";

export default function HistoryList() {
  const responseLength = 20;
  const { getSurveys, surveysLoading } = useSurveys();
  const [surveys, setSurveys] = useState([]);
  const [order, setOrder] = useState({ order: { label: "", value: 0 } });
  const [period, setPeriod] = useState({ period: { label: "Geral", value: 0 } });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    loadSurveys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    orderCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, period]);

  async function loadSurveys() {
    const skip = surveys.length;
    const take = responseLength;
    const year = period.period.value;

    try {
      const response = await getSurveys({
        order: order.order.value ? order.order.value : "",
        skip,
        take,
        year,
      });

      if (response) {
        setSurveys(surveys.concat(response));
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  async function orderCompanies() {
    const skip = 0;
    const take = responseLength;
    const year = period.period.value;

    try {
      const response = await getSurveys({
        order: order.order.value ? order.order.value : "",
        skip,
        take,
        year,
      });

      if (response) {
        setSurveys(response);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ form, setForm, name, value }) {
    setForm({ ...form, [name]: value });
  }

  return (
    <>
      <ParamsWrapper>
        <Select
          id="order"
          icon={CgReorder}
          placeholder={"Ordenar por..."}
          enabled={true}
          list={surveyUtils.orderList}
          selected={order.order}
          form={order}
          setForm={setOrder}
          handleForm={handleForm}
        />

        <Period
          period={period}
          setPeriod={setPeriod}
          handleForm={handleForm}
        />

        <Edit
          theme={theme}
          onClick={(e) => {
            e.preventDefault();
            setEditing(!editing);
          }}
        >
          {editing ? "Cancelar" : "Editar"}
        </Edit>
      </ParamsWrapper>

      {surveysLoading ? (
        <Loader />
      ) : (
        surveys.length === 0 ? <h2>Nenhuma resposta cadastrada neste período</h2> :
        (<ListWrapper
          offset={responseLength}
          list={surveys}
          loadList={loadSurveys}
          loading={surveysLoading}
        >
          {surveys.map((survey, index) => (
            <SurveyWrapper
              key={index}
              index={index}
              id={survey.id}
              name={survey.name}
              date={surveyUtils.formatDate(survey.createdAt)}
              editing={editing}
              surveys={surveys}
              setSurveys={setSurveys}
            />
          ))}
        </ListWrapper>)
      )}
    </>
  );
}

export function SurveyWrapper({
  index,
  id,
  name,
  date,
  editing,
  surveys,
  setSurveys,
}) {
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const { deleteSurvey } = useDeleteSurvey();

  return (
    <>
      <ListItem
        theme={theme}
        editing={editing}
        onClick={editing ? null : () => navigate(`pesquisa/${id}`)}
      >
        <div>
          <h5>{name}</h5>
          <p>{date}</p>
          {editing ? (
            <MdDeleteOutline onClick={() => setDeleting(true)} />
          ) : (
            <IoChevronForward />
          )}
        </div>
      </ListItem>

      {deleting && (
        <DeleteItem
          itemId={id}
          text="Tem certeza que deseja excluir esta pesquisa?"
          list={surveys}
          setList={setSurveys}
          index={index}
          deleting={deleting}
          setDeleting={setDeleting}
          action={deleteSurvey}
        />
      )}
    </>
  );
}
