const orderList = [
  { label: "Nome", value: "NOME" },
  { label: "Data", value: "CRIADO_EM" },
];

function formatDate(string) {
  return new Date(string)
    .toLocaleString("pt-BR", {
      timezone: "UTC",
    })
    .split(",")[0];
}

const surveyUtils = {
  orderList,
  formatDate,
};

export default surveyUtils;
