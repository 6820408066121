import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

import theme from "../../styles/theme";
import { Container, ScoreWrapper } from "./styles";

export default function Metrics({ averages }) {
   const average = (averages.quality +
    averages.professionalism +
    averages.interest +
    averages.relationship +
    averages.policies +
    averages.proactivity +
    averages.ethic +
    averages.recommendation +
    averages.punctuality) / 9;

    return (
    <Container theme={theme}>
        <h5>Métricas</h5>

        <div>
        <Score label="Média" value={average} />  
          <Score label="NPS" value={averages.recommendation} />  
        </div>
        
        
    </Container>)
}

function Score({ label, value }) {
    const text = value > 0 ? `+${Math.round(value)}` : Math.round(value)
    const normalizedValue = value > 0 ? value : -value;
    const counterClockwise = value > 0 ? false : true;
    const color = value > 0 ? theme.COLORS.BLUE : theme.COLORS.ORANGE;

    return (
      <ScoreWrapper theme={theme}>
        <h4>{label}</h4>

        <CircularProgressbar
            value={normalizedValue}
            text={text}
            counterClockwise={counterClockwise}
            styles={buildStyles({
                pathColor: color,
                textColor: theme.COLORS.GRAY_300,
                trailColor: theme.COLORS.GRAY_10,
                pathTransitionDuration: 0.5,
            })}
        />
      </ScoreWrapper> 
    );
}