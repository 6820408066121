import useAsync from "../useAsync";

import * as surveyApi from "../../services/surveyApi";
import useToken from "../useToken";

export default function useSurveys() {
  const token = useToken();

  const {
    data: surveys,
    loading: surveysLoading,
    error: surveysError,
    act: getSurveys,
  } = useAsync((data) => surveyApi.getSurveys(data, token), false);

  return {
    surveys,
    surveysLoading,
    surveysError,
    getSurveys,
  };
}
