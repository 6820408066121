import { useContext, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Button from "../components/Button";
import Input from "../components/Input";
import PasswordInput from "../components/PasswordInput";
import Label from "../components/Label";
import theme from "../styles/theme";
import loginUtils from "../utils/login-utils";
import {
  AuthenticationWrapper,
  ButtonsWrapper,
  Container,
  Form,
} from "./styles";
import PasswordInstructions from "../components/PasswordInstructions";
import useSignIn from "../hooks/api/useSignIn";
import UserContext from "../contexts/UserContext";
import toastUtils from "../utils/toast-utils";

export default function Login() {
  const { signIn } = useSignIn();
  const { setUserData } = useContext(UserContext);
  const [form, setForm] = useState(loginUtils.emptyForm);
  const [typingPassword, setTypingPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.search.replace("?redirect=", "");

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      const response = await signIn(form);

      setUserData(response);

      navigate(redirect ? redirect : "/restrito/historico");
    } catch (error) {
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.top,
        theme: toastUtils.theme.colored,
      });

      setForm({ ...form, password: "" });
    }
  }

  return (
    <Container theme={theme}>
      <AuthenticationWrapper theme={theme}>
        <h1>ACESSO RESTRITO</h1>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <span>
            <Label id="email" text="E-mail" value={form.email} />
            <Input
              id="email"
              placeholder="Digite seu e-mail de cadastro"
              type="email"
              size="long"
              required
              disabled={false}
              value={form.email}
              onChange={(e) =>
                handleForm({
                  name: e.target.id,
                  value: e.target.value,
                })
              }
            />
          </span>

          <span className="password">
            <Label id="password" text="Senha" value={form.password} />
            <PasswordInput
              placeholder="Digite sua senha"
              name="password"
              value={form.password}
              required
              onFocus={() => setTypingPassword(true)}
              onBlur={() => setTypingPassword(false)}
              onChange={(e) =>
                handleForm({
                  name: e.target.name,
                  value: e.target.value,
                })
              }
              minLength="8"
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
            />

            <PasswordInstructions
              typingPassword={typingPassword}
              password={form.password}
            />
          </span>

          <ButtonsWrapper theme={theme}>
            <Button
              title="Cancelar"
              type="button"
              className="clear"
              onClick={() => navigate("/")}
            />
            <Button title="Entrar" />
          </ButtonsWrapper>
        </Form>

        <Link to="/alterar-senha/email">Alterar minha senha</Link>
      </AuthenticationWrapper>
    </Container>
  );
}
