import styled from "styled-components";

export const Container = styled.input`
  background-color: ${({ theme }) => theme.COLORS.BLUE};
  color: white;
  cursor: ${({ disabled }) => (disabled ? "initial" : "pointer")};
  border: 0;
  padding: 10px;
  margin: 16px 0;
  border-radius: 5px;
  font-weight: 700;
  font-size: 18px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

  &:disabled {
    background-color: ${({ theme }) => theme.COLORS.GRAY_100};
  }

  &:hover {
    opacity: 80%;
  }
`;
