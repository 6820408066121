import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 105px);
  margin-top: 105px;
  padding: 30px ${({ selected }) => (selected === "charts" ? "5%" : "15%")};
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_100};

  & form > span {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
  }

  h1 {
    color: ${({ theme }) => theme.COLORS.BLUE};
    margin-bottom: 50px;
  }

  h3 {
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 18px;
    font-weight: 400;
    position: absolute;
    top: 180px;
  }

  h2 {
    height: 30px;
    text-align: center;
    margin-top: calc(30vh - 30px);
    color: ${({ theme }) => theme.COLORS.BLUE};
  }

  p {
    color: ${({ theme }) => theme.COLORS.GRAY_300};
    line-height: 22px;
    margin-bottom: 30px;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.BLUE};

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 767px) {
    margin-top: 80px;
  }

  @media (max-width: 1023px) {
    padding: 20px;
    min-height: calc(100vh - 80px);

    & form > span {
      margin-bottom: 40px;
    }

    h1 {
      font-size: 24px;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  position: relative;
  margin-bottom: 140px;

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;

  .clear,
  .back {
    background-color: transparent;
    color: ${({ theme }) => theme.COLORS.BLUE};
    font-weight: 400;
    box-shadow: none;
    margin-right: 10px;
  }

  .back {
    margin-right: 0;
    width: auto !important;
  }
`;

export const AuthenticationWrapper = styled.div`
  width: 60%;
  padding: 40px;
  margin: 10% auto;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;

  form {
    margin-bottom: 0 !important;
  }

  span {
    margin-bottom: 50px !important;

    &:last-of-type {
      margin-bottom: 30px !important;
    }
  }

  .password {
    position: relative;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    margin: 25% auto;
  }

  @media (max-width: 767px) {
    width: 100%;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
    margin: 0;

    a {
      position: absolute;
      bottom: 20px;
      left: calc(50% - 100px);
      width: 200px;
      text-align: center;
    }
  }
`;

export const HistoryOptions = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const OptionWrapper = styled.span`
  color: ${({ theme, selected }) =>
    selected ? "white" : theme.COLORS.GRAY_300};
  background-color: ${({ theme, selected }) =>
    selected ? theme.COLORS.BLUE : "transparent"};
  border-radius: 5px;
  padding: 10px;
  margin: 0 10px;
  cursor: ${({ selected }) => (selected ? "default" : "pointer")};
  font-weight: 700;
  transition-duration: 0ms;

  &:hover {
    color: ${({ theme, selected }) => (selected ? "white" : theme.COLORS.BLUE)};
  }
`;

export const ParamsWrapper = styled.span`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 30px 0 10px 0;
  position: relative;

  & > div {
    width: 240px;
    height: 50px;
    margin-bottom: 0;
    position: initial;
    margin-right: 10px;

    .select {
      max-width: 190px;
    }

    .css-1u9des2-indicatorSeparator {
      display: none;
    }
  }
`;

export const ListItem = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);

  div {
    width: 100;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    margin: 0;
  }

  p {
    position: absolute;
    right: 30px;
    color: ${({ theme }) => theme.COLORS.GRAY_10};
    margin: 0;
  }

  svg {
    color: ${({ theme, editing }) =>
      editing ? theme.COLORS.RED + "95" : theme.COLORS.BLUE};

    &:hover {
      color: ${({ theme, editing }) =>
        editing ? theme.COLORS.RED : theme.COLORS.BLUE} !important;
    }
  }

  &:hover {
    svg {
      color: ${({ theme, editing }) =>
        editing ? theme.COLORS.RED + "95" : theme.COLORS.BLUE};
    }

    h5 {
      color: ${({ theme, editing }) =>
        editing ? theme.COLORS.GRAY_100 : theme.COLORS.BLUE};
    }
  }
`;

export const ChartsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: center;
`;

export const Edit = styled.button`
  color: ${({ theme }) => theme.COLORS.GRAY_100};
  font-size: 16px;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 0;

  &:hover {
    color: ${({ theme }) => theme.COLORS.BLUE};
  }
`;

export const PresetScoreWrapper = styled.div`
  width: 100%;
  margin-bottom: 50px;

  .selection {
    display: flex;

    p {
      margin: 0 !important;
    }

    p:last-child {
      margin-left: 5px !important;
      color: ${({ theme, rating }) => theme.RATING[rating]}; 
      font-weight: 700;
    }
  }

  svg {
      width: 50px;
      height: 50px;
      color: ${({ theme, rating }) => theme.RATING[rating]}; 
      margin-top: 20px;
    }
`;