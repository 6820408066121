import { useEffect, useState } from "react"; 

import useResults from "../hooks/api/useResults";
import resultUtils from "../utils/result-utils";
import toastUtils from "../utils/toast-utils";
import { ChartsWrapper, ParamsWrapper } from "./styles";
import Loader from "../components/Loader";
import Chart from "../components/Chart";
import homeUtils from "../utils/home-utils";
import Period from "../components/Period";
import Metrics from "../components/Metrics";

export default function HistoryResults() {
  const { getResults, resultsLoading } = useResults();
  const [results, setResults] = useState(resultUtils.emptyResults);
  const [averages, setAverages] = useState(resultUtils.emptyResults);
  const [empty, setEmpty] = useState(true);
  const [period, setPeriod] = useState({ period: { label: "Geral", value: 0 } });

  useEffect(() => {
    loadResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    const completedSurvey = results.quality.find((item) => item.count);

    if (completedSurvey) setEmpty(false);
    else setEmpty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  async function loadResults() {
    const year = period.period.value;
    try {
      const response = await getResults(year);

      if (response) {
        setResults(response.results);
        setAverages(response.averages);
      }
    } catch (error) {
      toastUtils.toaster({
        message: error.response?.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ form, setForm, name, value }) {
    setForm({ ...form, [name]: value });
  }

  return (
    <>
      <ParamsWrapper>
        <Period
          period={period}
          setPeriod={setPeriod}
          handleForm={handleForm}
        />
      </ParamsWrapper>

      {resultsLoading ? (
        <Loader />
      ) : (results !== resultUtils.emptyResults && !empty) ? (
        <ChartsWrapper>
          <Chart
            data={results.quality}
            average={averages.quality}
            label="Qualidade"
            question={homeUtils.questions.quality.pt}
          />

          <Chart
            data={results.professionalism}
            average={averages.professionalism}
            label="Profissionalismo"
            question={homeUtils.questions.professionalism.pt}
          />

          <Chart
            data={results.interest}
            average={averages.interest}
            label="Assuntos de Interesse"
            question={homeUtils.questions.interest.pt}
          />

          <Chart
            data={results.relationship}
            average={averages.relationship}
            label="Relacionamento"
            question={homeUtils.questions.relationship.pt}
          />

          <Chart
            data={results.policies}
            average={averages.policies}
            label="Respeito às Políticas"
            question={homeUtils.questions.policies.pt}
          />

          <Chart
            data={results.proactivity}
            average={averages.proactivity}
            label="Proatividade"
            question={homeUtils.questions.proactivity.pt}
          />

          <Chart
            data={results.ethic}
            average={averages.ethic}
            label="Ética"
            question={homeUtils.questions.ethic.pt}
          />

          <Chart
            data={results.recommendation}
            average={averages.recommendation}
            label="Recomendação"
            question={homeUtils.questions.recommendation.pt}
          />

          <Chart
            data={results.punctuality}
            average={averages.punctuality}
            label="Pontualidade"
            question={homeUtils.questions.punctuality.pt}
          />

          <Metrics
            averages={averages}
          />
        </ChartsWrapper>
      ) : (
        <h2>Nenhum resultado encontrado</h2>
      )}
    </>
  );
}
