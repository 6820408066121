import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import theme from "../../styles/theme";
import { Container } from "./styles";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function Chart({ label, data, question, average }) {
  const info = {
    labels: data.map((item) => item.description),
    datasets: [
      {
        label: "Respostas",
        data: data.map((item) => item.count),
        backgroundColor: data.map((item) => theme.RATING[item.value]),
        fontColor: data.map((item) => theme.RATING[item.value]),
      },
    ],
  };

  const total = info.datasets[0].data.reduce((a, b) => a + b, 0);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const label = "Fração";
            const value = info.datasets[0].data[context.dataIndex];
            const total = info.datasets[0].data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            return label + ": " + percentage + "%";
          },
        },
      },
      datalabels: {
        display: true,
        color: theme.COLORS.WHITE,
        font: {
          size: 16,
          weight: 700,
        },
        formatter: (value, context) => {
          const percentage = Math.round((value / total) * 100);
          return value !== 0 ? `${percentage}%` : "";
        }
      }
    },
  };

  return (
    <>
      <Container theme={theme}>
        <h5>{label}</h5>
        <p>{question}</p>
        <Doughnut data={info} options={options} />
        <h3>{average > 0 ? `+${Math.round(average)}` : Math.round(average)}</h3>
      </Container>
    </>
  );
}
