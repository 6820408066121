import { Link } from "react-router-dom";
import React from "react";
import { useState } from "react";

import { AuthenticationWrapper, ButtonsWrapper, Form } from "../styles";
import Input from "../../components/Input";
import Button from "../../components/Button";
import theme from "../../styles/theme";
import usePasswordChangeEmail from "../../hooks/api/usePasswordChangeEmail";
import toastUtils from "../../utils/toast-utils";
import Label from "../../components/Label";

export default function EmailVerification() {
  const { getPasswordChangeEmail } = usePasswordChangeEmail();
  const [email, setEmail] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await getPasswordChangeEmail(email);
      setEmail("");
      toastUtils.toaster({
        message: "Link enviado para e-mail!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      setEmail("");
      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <AuthenticationWrapper theme={theme}>
      <h1>ALTERAR SENHA</h1>
      <Form theme={theme} onSubmit={handleSubmit}>
        <p>
          Insira seu e-mail abaixo para receber o link de alteração de senha
        </p>

        <span className="email">
          <Label id="email" text="E-mail" value={email} />
          <Input
            placeholder="E-mail"
            type="email"
            name="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
        </span>

        <ButtonsWrapper theme={theme}>
          <Button title="Enviar E-mail" />
        </ButtonsWrapper>

        <Link to="/login">Voltar para o login</Link>
      </Form>
    </AuthenticationWrapper>
  );
}
