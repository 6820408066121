/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  FaRegFaceLaugh,
  FaRegFaceSmile,
  FaRegFaceMeh,
  FaRegFaceFrown,
  FaRegFaceFrownOpen
} from "react-icons/fa6";

import theme from "../styles/theme";
import Input from "../components/Input";
import Label from "../components/Label";
import { ButtonsWrapper, Container, Form, PresetScoreWrapper } from "./styles";
import CheckBoxGroup from "../components/CheckBoxGroup";
import useScores from "../hooks/api/useScores";
import toastUtils from "../utils/toast-utils";
import Button from "../components/Button";
import homeUtils from "../utils/home-utils";
import useSaveSurvey from "../hooks/api/useSaveSurvey";
import Translator from "../components/Translator";
import { useSearchParams } from "react-router-dom";

export default function Home() {
  const [searchParams] = useSearchParams();
  const id = Number(searchParams.get("id"));
  const { getScores } = useScores();
  const { saveSurvey } = useSaveSurvey();
  const [scores, setScores] = useState({ pt: [], en: [] });
  const [form, setForm] = useState(homeUtils.emptyForm);
  const [language, setLanguage] = useState("pt");
  const [presetScore, setPresetScore] = useState(null);

  useEffect(() => {
    loadScores();
  }, []);

  useEffect(() => {
    if (!id) return;

    setPresetScore(scores[language].find((item) => item.id === id));
    
    setForm({
      ...form,
      quality: id,
      professionalism: id,
      interest: id,
      relationship: id,
      policies: id,
      proactivity: id,
      ethic: id,
      recommendation: id,
      punctuality: id,
    });
  }, [id, scores, language]);

  async function loadScores() {
    try {
      const result = await getScores();

      if (result) setScores(homeUtils.translateScores(result));
    } catch (error) {
      toastUtils.toaster({
        message: error.response?.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      let body = { ...form };
      delete body.createdAt;

      await saveSurvey(body);

      toastUtils.toaster({
        message: "Enviado!",
        type: toastUtils.type.success,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });

      setForm(homeUtils.emptyForm);
    } catch (error) {
      toastUtils.toaster({
        message: error.response?.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topCenter,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <Container theme={theme}>
      <Translator language={language} setLanguage={setLanguage} />

      <h1>
        {language === "pt" ? "PESQUISA DE SATISFAÇÃO" : "SATISFACTION SURVEY"}
      </h1>

      {presetScore && (< PresetScoreMessage info={presetScore} language={language} />)}

      <Form onSubmit={(e) => handleSubmit(e)}>
        <span>
          <Label
            id="name"
            text={homeUtils.questions.name[language] + "*"}
            value={form.name}
          />
          <Input
            id="name"
            placeholder={homeUtils.placeholder[language]}
            type="text"
            size="short"
            required
            disabled={false}
            value={form.name}
            onChange={(e) =>
              handleForm({
                name: e.target.id,
                value: e.target.value,
              })
            }
          />
        </span>

        <span>
          <Label id="email" text="E-mail*" value={form.email} />
          <Input
            id="email"
            placeholder={homeUtils.placeholder[language]}
            type="email"
            size="short"
            required
            disabled={false}
            value={form.email}
            onChange={(e) =>
              handleForm({
                name: e.target.id,
                value: e.target.value,
              })
            }
          />
        </span>

        <span>
          <Label
            id="quality"
            text={homeUtils.questions.quality[language]}
            value={form.quality}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="quality"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="professionalism"
            text={homeUtils.questions.professionalism[language]}
            value={form.professionalism}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="professionalism"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="interest"
            text={homeUtils.questions.interest[language]}
            value={form.interest}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="interest"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="relationship"
            text={homeUtils.questions.relationship[language]}
            value={form.relationship}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="relationship"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="policies"
            text={homeUtils.questions.policies[language]}
            value={form.policies}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="policies"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="proactivity"
            text={homeUtils.questions.proactivity[language]}
            value={form.proactivity}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="proactivity"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="ethic"
            text={homeUtils.questions.ethic[language]}
            value={form.ethic}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="ethic"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="recommendation"
            text={homeUtils.questions.recommendation[language]}
            value={form.recommendation}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="recommendation"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="punctuality"
            text={homeUtils.questions.punctuality[language]}
            value={form.punctuality}
          />
          <CheckBoxGroup
            list={scores[language]}
            id="punctuality"
            form={form}
            handleForm={handleForm}
          />
        </span>

        <span>
          <Label
            id="suggestions"
            text={homeUtils.questions.suggestions[language]}
            value={form.suggestions}
          />
          <Input
            id="suggestions"
            placeholder={homeUtils.placeholder[language]}
            type="text"
            size="long"
            disabled={false}
            value={form.suggestions}
            onChange={(e) =>
              handleForm({
                name: e.target.id,
                value: e.target.value,
              })
            }
          />
        </span>

        <ButtonsWrapper theme={theme}>
          <Button
            title={language === "pt" ? "Apagar" : "Clear"}
            type="button"
            className="clear"
            onClick={() => setForm(homeUtils.emptyForm)}
          />
          <Button title={language === "pt" ? "Enviar" : "Send"} />
        </ButtonsWrapper>
      </Form>
    </Container>
  );
}

function PresetScoreMessage({ info, language }) {
  return (
    <PresetScoreWrapper theme={theme} rating={info.value}>
      <p>{homeUtils.presetMessage.intro[language]}</p>

      <span className="selection">
        <p>{homeUtils.presetMessage.selection[language]}</p>
        <p>{`${info.description}.`}</p>
      </span>
      

      {info.id === 1 && <FaRegFaceLaugh />}
      {info.id === 3 && <FaRegFaceSmile />}
      {info.id === 4 && <FaRegFaceMeh />}
      {info.id === 2 && <FaRegFaceFrown />}
      {info.id === 5 && <FaRegFaceFrownOpen />}

      <p>{info.message[language]}</p>

      <p>{homeUtils.presetMessage.end[language]}</p>
    </PresetScoreWrapper>
  );
}
