import React from "react";
import { Outlet } from "react-router-dom";

import { Container } from "../styles";
import theme from "../../styles/theme";

export default function ChangePassword() {
  return (
    <Container theme={theme}>
      <Outlet />
    </Container>
  );
}
