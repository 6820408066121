import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 30px;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  position: relative;

  p {
    color: ${({ theme }) => theme.COLORS.GRAY_300};
    margin-bottom: 30px;
    line-height: 22px;
    height: 70px;
  }

  h5 {
    color: ${({ theme }) => theme.COLORS.BLUE};
    margin: 0 0 20px 0;
    font-size: 20px;
  }

  h3 {
    width: 100px;
    height: 30px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.BLUE};
    font-weight: 700;
    font-size: 22px;
    position: absolute;
    top: 310px;
    left: calc(50% - 50px);
  }

  .alternative-message {
    line-height: 24px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
  }

  @media (max-width: 1023px) {
    & > div {
      flex-direction: row;
      margin: 20px 0;
    }  
  }

  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;

export const ScoreWrapper = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0;

  h4 {
    margin: 10px 0;
    color: ${({ theme }) => theme.COLORS.GRAY_300};
  }

  @media (max-width: 767px) {
    height: 120px;
  }
`;
