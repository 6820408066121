const emptyForm = {
  name: "",
  email: "",
  suggestions: "",
  quality: 0,
  professionalism: 0,
  interest: 0,
  relationship: 0,
  policies: 0,
  proactivity: 0,
  ethic: 0,
  recommendation: 0,
  punctuality: 0,
  createdAt: "",
};

const questions = {
  name: {
    pt: "Nome",
    en: "Name",
  },
  quality: {
    pt: "Como está sua satisfação em relação à qualidade do serviço prestado?",
    en: "How satisfied are you with the quality of the service provided?",
  },
  professionalism: {
    pt: "Como está sua satisfação em relação ao quanto profissional você acha a nossa empresa?",
    en: "How professional do you perceive our company to be?",
  },
  interest: {
    pt: "Como está sua satisfação em relação à abordagem de assuntos de interesse da organização?",
    en: "How satisfied are you with the approach to topics of interest of the organization?",
  },
  relationship: {
    pt: "Como está sua satisfação em relação ao bom relacionamento com os colaboradores da organização?",
    en: "How satisfied are you with the relationship with the organization's employees?",
  },
  policies: {
    pt: "Como está sua satisfação em relação ao respeito às políticas da organização?",
    en: "How satisfied are you with the adherence to the organization's policies?",
  },
  proactivity: {
    pt: "Como está sua satisfação em relação à proatividade?",
    en: "How satisfied are you with the proactivity of our employees?",
  },
  ethic: {
    pt: "Como está sua satisfação em relação à ética?",
    en: "How satisfied are you with the ethics?",
  },
  recommendation: {
    pt: "Como está sua satisfação em relação a recomendar nossa empresa para outras pessoas?",
    en: "How likely are you to recommend our company to others?",
  },

  punctuality: {
    pt: "Como está sua satisfação em relação à nossa pontualidade?",
    en: "How satisfied are you with our punctuality?",
  },

  suggestions: {
    pt: "Sugestões de melhorias",
    en: "Suggestions for improvements",
  },
};

const placeholder = {
  pt: "Sua resposta",
  en: "Your answer",
};

const presetMessage = {
  intro: {
    pt: "Obrigado por compartilhar seu feedback conosco!",
    en: "Thank you for sharing your feedback with us! ",
  },
  selection: {
    pt: "Você selecionou a opção:",
    en: "You've selected the option:",
  },
  end: {
    pt: "Selecionamos os campos de acordo com a sua resposta. Fique à vontade para alterá-los e deixar uma mensagem ao final.",
    en: "We have selected the fields according to your response. Feel free to change them and leave a message at the end.",
  }
}

function translateScores(scores) {
  const transelatedScores = scores.map((score) => {
    let result = { ...score };
    if (score.value === 10) result.description = "Very satisfied";
    if (score.value === 9) result.description = "Satisfied";
    if (score.value === 8) result.description = "Indifferent";
    if (score.value === 7) result.description = "Displeased";
    if (score.value === 6) result.description = "Very displeased";
    return result;
  });

  return {
    pt: scores,
    en: transelatedScores,
  };
}

const homeUtils = {
  emptyForm,
  questions,
  placeholder,
  presetMessage,
  translateScores,
};

export default homeUtils;
