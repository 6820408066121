import theme from "../../styles/theme";
import { Container } from "./styles";

export default function Input({ icon: Icon, ...rest }) {
  return (
    <Container theme={theme} disabled={rest.disabled} size={rest.size}>
      {Icon && <Icon size={20} />}
      <input {...rest} />
    </Container>
  );
}
