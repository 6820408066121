import styled from "styled-components";

export const Container = styled.div`
  display: ${({ typingPassword }) => (typingPassword ? "flex" : "none")};
  flex-direction: column;
  align-items: baseline;
  width: calc(100% - 10px);
  padding-left: 16px;
  margin-bottom: 10px;
  text-align: left;
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 2;
  background-color: white;

  p {
    font-size: 12px;
    margin: 2px 0;
    color: ${({ theme }) => theme.COLORS.GRAY_300};
    text-align: left;
  }

  #length {
    color: ${({ theme, requirements }) =>
      requirements.length ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }

  #num {
    color: ${({ theme, requirements }) =>
      requirements.num ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }
  #special-char {
    color: ${({ theme, requirements }) =>
      requirements.specialChar ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }
  #upper-case {
    color: ${({ theme, requirements }) =>
      requirements.upperCase ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }
  #lower-case {
    color: ${({ theme, requirements }) =>
      requirements.lowerCase ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};
  }
`;
