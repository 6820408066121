import styled from "styled-components";

export const Container = styled.div`
  width: ${({ size }) => (size === "short" ? "60%" : "100%")};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.GRAY_300};
  margin-bottom: 14px;
  position: relative;

  input[type="text"],
  input[type="email"] {
    width: 100%;
    text-overflow: ellipsis;
    padding-bottom: 5px;
    color: ${({ theme }) => theme.COLORS.GRAY_300};
    background: transparent;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_100};
    font-size: 18px;
    outline: none;
    transition: border-bottom-width 0s;

    &:placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_100};
    }

    &:focus {
      box-shadow: 0 0 0 0;
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.COLORS.BLUE};
      outline: 0;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      transition: width 0s;
    }

    &:focus::after {
      width: 100%;
    }

    &:autofill {
      box-shadow: 0 0 0 30px white inset;
      color: ${({ theme }) => theme.COLORS.GRAY_300}!important;
      border-radius: 0 10px 10px 0;
    }

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px white inset;
      -webkit-text-fill-color: ${({ theme }) =>
        theme.COLORS.GRAY_300}!important;
      border-radius: 0 10px 10px 0;
    }
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  svg {
    margin-left: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100};
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }

  @media(max-width: 1023px) {
    width: 100%;

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      margin-top: 20px;
      margin-right: 10px;
    }
  }
`;
