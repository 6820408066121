const theme = {
  COLORS: {
    BACKGROUND_900: "#232129",
    BACKGROUND_800: "#312E38",
    BACKGROUND_700: "#3E3B47",
    BACKGROUND_600: "#4b4854",
    BACKGROUND_100: "#EEEEEE",

    WHITE: "#F4EDE8",
    ORANGE: "#FF9000",
    BLUE: "#003366",
    GRAY: "#333333",
    GRAY_TRANSLUCENT: "#333333bf",

    GRAY_10: "#cfcfcf",
    GRAY_100: "#999591",
    GRAY_300: "#666360",

    RED: "#c30010",
  },

  RATING: {
    10: "#37a76f",
    9: "#76c565",
    8: "#e2a641",
    7: "#c94a3d",
    6: "#e6161b",
  },
};

export default theme;
