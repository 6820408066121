import "./index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ChangePassword from "./pages/ChangePassword";
import EmailVerification from "./pages/ChangePassword/EmailVerification";
import NewPassword from "./pages/ChangePassword/NewPassword";
import History from "./pages/History";
import { UserProvider } from "./contexts/UserContext";
import { Header } from "./components/Header";
import HistorySurvey from "./pages/HistorySurvey";
import Restricted from "./pages/Restricted";
import useToken from "./hooks/useToken";
import toastUtils from "./utils/toast-utils";
import theme from "./styles/theme";

function App() {
  return (
    <UserProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme
      />

      <div className="App">
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />

            <Route path="/alterar-senha" element={<ChangePassword />}>
              <Route path="email" element={<EmailVerification />} />
              <Route path="nova-senha" element={<NewPassword />} />
            </Route>

            <Route
              path="/restrito"
              element={
                <ProtectedRouteGuard>
                  <Restricted />
                </ProtectedRouteGuard>
              }
            >
              <Route path="sign-up" element={<SignUp />} />
              <Route path="historico" element={<History />} />
              <Route
                path="historico/pesquisa/:id"
                element={<HistorySurvey />}
              />
            </Route>
          </Routes>
        </Router>
      </div>
    </UserProvider>
  );
}

function ProtectedRouteGuard({ children }) {
  const token = useToken();
  const path = useLocation().pathname;

  if (!token) {
    toastUtils.toaster({
      message: "Faça login para continuar!",
      type: toastUtils.type.default,
      position: toastUtils.position.topCenter,
      theme: { box: theme.COLORS.BLUE, progressBar: theme.COLORS.WHITE },
    });

    return <Navigate to={`/login?redirect=${path}`} />;
  }

  return <>{children}</>;
}

export default App;
