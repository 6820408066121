import { Link, useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useState } from "react";

import { AuthenticationWrapper, ButtonsWrapper, Form } from "../styles";
import Button from "../../components/Button";
import PasswordInstructions from "../../components/PasswordInstructions";
import theme from "../../styles/theme";
import useChangePassword from "../../hooks/api/useChangePassword";
import toastUtils from "../../utils/toast-utils";
import PasswordInput from "../../components/PasswordInput";
import Label from "../../components/Label";

export default function NewPassword() {
  const { changePassword } = useChangePassword();
  const navigate = useNavigate();
  const location = useLocation();
  const activationKey = new URLSearchParams(location.search).get("key");
  const userId = new URLSearchParams(location.search).get("userId");
  const [typingPassword, setTypingPassword] = useState(false);
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });
  console.log(form);
  function handleForm({ name, value }) {
    setForm({ ...form, [name]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await changePassword({
        userId,
        key: activationKey,
        password: form.password,
        confirmPassword: form.confirmPassword,
      });

      setForm({
        password: "",
        confirmPassword: "",
      });

      navigate("/login");

      toastUtils.toaster({
        message: "Senha alterada com sucesso!",
        type: toastUtils.type.success,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    } catch (error) {
      setForm({
        password: "",
        confirmPassword: "",
      });

      toastUtils.toaster({
        message: error.response.data,
        type: toastUtils.type.error,
        position: toastUtils.position.topLeft,
        theme: toastUtils.theme.colored,
      });
    }
  }

  return (
    <AuthenticationWrapper theme={theme}>
      <h1> ALTERAR SENHA </h1>
      <Form theme={theme} onSubmit={handleSubmit}>
        <span className="password">
          <Label id="password" text="Senha" value={form.password} />
          <PasswordInput
            placeholder="Nova Senha"
            name="password"
            value={form.password}
            required
            onFocus={() => setTypingPassword(true)}
            onBlur={() => setTypingPassword(false)}
            onChange={(e) =>
              handleForm({
                name: e.target.name,
                value: e.target.value,
              })
            }
            minLength="8"
            pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
          />

          <PasswordInstructions
            typingPassword={typingPassword}
            password={form.password}
          />
        </span>

        <span className="confirmPassword">
          <Label
            id="confirmPassword"
            text="Confirmação de senha"
            value={form.confirmPassword}
          />
          <PasswordInput
            placeholder="Confirme sua senha"
            name="confirmPassword"
            value={form.confirmPassword}
            required
            onChange={(e) =>
              handleForm({
                name: e.target.name,
                value: e.target.value,
              })
            }
            minLength="8"
            pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d{2,})(?=.*[^\w\s]).{8,}$"
          />
        </span>

        <ButtonsWrapper theme={theme}>
          <Button title="Alterar senha" />
        </ButtonsWrapper>

        <Link to="/login">Voltar para o login</Link>
      </Form>
    </AuthenticationWrapper>
  );
}
