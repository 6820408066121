import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  position: relative;
  min-width: 820px;
  max-width: 1040px;
  margin: 20px 0 30px 0;

  @media(max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: baseline;
    min-width: 0;
    margin-bottom: 0;
  }
`;

export const BoxWrapper = styled.div`
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, checked }) =>
    checked ? theme.COLORS.BLUE : theme.COLORS.GRAY_100};

  input[type="checkbox"] {
    -webkit-appearance: none; /* Remove a aparência padrão do checkbox no Safari/Chrome */
    -moz-appearance: none; /* Remove a aparência padrão do checkbox no Firefox */
    appearance: none; /* Remove a aparência padrão do checkbox em outros navegadores */
    width: 20px;
    height: 20px;
    border: 2px solid ${({ theme }) => theme.COLORS.GRAY_100};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.COLORS.BACKGROUND_100};
    outline: none;
    transition: border-color 0.2s;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    z-index: 1;
  }

  input[type="checkbox"]:checked {
    background-color: ${({ theme, score }) => theme.RATING[score]};
    border: 3px solid ${({ theme }) => theme.COLORS.BLUE};
  }

  input[type="checkbox"]:hover {
    border-color: ${({ theme, disabled, checked }) =>
      disabled && !checked ? theme.COLORS.GRAY_100 : theme.COLORS.BLUE};
  }

  @media(max-width: 1023px) {
    flex-direction : row;
    
    label {
      margin-left: 10px;
    }
  }
`;

export const Line = styled.div`
  width: 77%;
  height: 2px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.COLORS.GRAY_100};
  position: absolute;
  top: 12px;
  left: 11.5%;

  @media(max-width: 1023px) {
    height: 77%;
    width: 2px;
    left: 13px;
    top: 30px;
  }
`;
