import useAsync from "../useAsync";

import * as surveyApi from "../../services/surveyApi";
import useToken from "../useToken";

export default function useDeleteSurvey() {
  const token = useToken();

  const {
    loading: deleteSurveyLoading,
    error: deleteSurveyError,
    act: deleteSurvey,
  } = useAsync((data) => surveyApi.deleteSurvey(data, token), false);

  return {
    deleteSurveyLoading,
    deleteSurveyError,
    deleteSurvey,
  };
}
