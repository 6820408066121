const emptyResults = {
  quality: [],
  professionalism: [],
  interest: [],
  relationship: [],
  policies: [],
  proactivity: [],
  ethic: [],
  recommendation: [],
  punctuality: [],
};

const emptyAverages = {
  quality: 0,
  professionalism: 0,
  interest: 0,
  relationship: 0,
  policies: 0,
  proactivity: 0,
  ethic: 0,
  recommendation: 0,
  punctuality: 0,
};

const resultUtils = {
  emptyResults,
  emptyAverages,
};

export default resultUtils;
