import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  align-items: center;
  background-color: white;
  color: ${({ theme }) => theme.COLORS.GRAY_300};
  padding-right: 10px;
  margin-bottom: 14px;
  border-radius: 10px;
  border: 1px solid rgbw(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 3px;
  font-size: 18px;
  white-space: pre-wrap;
  position: absolute;
  left: 0;
  bottom: 0;

  & > svg {
    margin-left: 16px;
    color: ${({ theme }) => theme.COLORS.GRAY_100} !important;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
  }

  & > div {
    width: 100%;
  }

  .select {
    text-overflow: ellipsis;

    .css-1xc3v61-indicatorContainer {
      padding: 0;
    }

    span {
      margin: 0;
    }
  }
`;
