import { Container, Flag } from "./styles";
import BrFlag from "../../assets/BR-Flag.png";
import UsFlag from "../../assets/US-Flag.png";
import theme from "../../styles/theme";

export default function Translator({ language, setLanguage }) {
  return (
    <Container>
      <Flag
        src={BrFlag}
        alt="Pt-BR"
        id="pt"
        onClick={() => setLanguage("pt")}
        language={language}
        theme={theme}
      />
      <Flag
        src={UsFlag}
        alt="En-US"
        id="en"
        onClick={() => setLanguage("en")}
        language={language}
        theme={theme}
      />
    </Container>
  );
}
