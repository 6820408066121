import useAsync from "../useAsync";

import * as authApi from "../../services/authApi";

export default function usePasswordChangeEmail() {
  const {
    data: passwordChangeEmail,
    loading: passwordChangeEmailLoading,
    error: passwordChangeEmailError,
    act: getPasswordChangeEmail,
  } = useAsync((data) => authApi.sendPasswordChangeEmail(data), false);

  return {
    passwordChangeEmail,
    passwordChangeEmailLoading,
    passwordChangeEmailError,
    getPasswordChangeEmail,
  };
}
