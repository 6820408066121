const emptyForm = {
  email: "",
  password: "",
};

const emptySignUpForm = {
  email: "",
  password: "",
  confirmPassword: "",
};

function validatePassword({ password, requirements, setRequirements }) {
  let requirementsStatus = requirements;

  if (password.length >= 8)
    // testa mínimo de 8 caracteres
    requirementsStatus = {
      ...requirementsStatus,
      length: true,
    };
  else requirementsStatus = { ...requirementsStatus, length: false };

  if (/\d{2,}/.test(password))
    // testa mínimo de 2 caracteres numéricos
    requirementsStatus = {
      ...requirementsStatus,
      num: true,
    };
  else requirementsStatus = { ...requirementsStatus, num: false };

  if (/[^a-zA-Z\d]/.test(password))
    // testa mínimo de 1 caractere especial
    requirementsStatus = {
      ...requirementsStatus,
      specialChar: true,
    };
  else requirementsStatus = { ...requirementsStatus, specialChar: false };

  if (/[A-Z]/.test(password))
    // testa mínimo de 1 letra maiúscula
    requirementsStatus = {
      ...requirementsStatus,
      upperCase: true,
    };
  else requirementsStatus = { ...requirementsStatus, upperCase: false };

  if (/[a-z]/.test(password))
    // testa mínimo de 1 letra minúscula
    requirementsStatus = {
      ...requirementsStatus,
      lowerCase: true,
    };
  else requirementsStatus = { ...requirementsStatus, lowerCase: false };

  setRequirements(requirementsStatus);
}

const loginUtils = {
  emptyForm,
  emptySignUpForm,
  validatePassword,
};

export default loginUtils;
