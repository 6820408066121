import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  width: inherit;
  overflow: hidden;
  color: ${({ theme, value }) =>
    value ? theme.COLORS.BLUE : theme.COLORS.GRAY_300};
  margin-bottom: 30px;

  label {
    width: 100%;
    font-size: 18px;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    @media(max-width: 1023px) {
      white-space: pre-wrap;
    }
  }

  @media(max-width: 1023px) {
    margin-bottom: 0;

    label {
      white-space: pre-wrap;
    }  
  }
`;
