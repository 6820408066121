import { IoCalendarClearOutline } from "react-icons/io5";

import Select from "../Select";

export default function Period({ period, setPeriod, handleForm }) {
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const years = [{ label: "Geral", value: 0 }];

  for (let year = startYear; year <= currentYear; year++) {
    years.push({label: year.toString(), value: year });
  }

  return (
    <Select
        id="period"
        icon={IoCalendarClearOutline}
        placeholder={"Geral"}
        enabled={true}
        list={years}
        selected={period.period}
        form={period}
        setForm={setPeriod}
        handleForm={handleForm}
      />
  )
}