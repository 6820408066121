import useAsync from "../useAsync";

import * as resultApi from "../../services/resultApi";
import useToken from "../useToken";

export default function useResults() {
  const token = useToken();

  const {
    loading: resultsLoading,
    error: resultsError,
    act: getResults,
  } = useAsync((data) => resultApi.getResults(token, data), false);

  return {
    resultsLoading,
    resultsError,
    getResults,
  };
}
