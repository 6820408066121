import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: absolute;
  right: 80px;
  top: 156px;

  @media (max-width: 1023px) and (min-width: 768px) {
    top: 144px;
  }

  @media (max-width: 767px) {
    right: 20px;
    top: 88px;
  }
`;

export const Flag = styled.img`
  width: 30px;
  transition-duration: 0ms;
  cursor: ${({ language, id }) => (language === id ? "default" : "pointer")};
  border: ${({ language, id, theme }) =>
    language === id ? `2px solid ${theme.COLORS.BLUE}` : "none"};
`;
