import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 105px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  background: white;

  .restrictedAccess {
    display: flex;
    align-items: center;
  }

  svg {
    font-size: 18px;
    color: ${({ theme }) => theme.COLORS.BLUE};
    margin: 0 5px 4px 0;
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.COLORS.BLUE};
    line-height: 18px;
  }

  a:hover {
    text-decoration: underline;
  }

  @media(max-width: 767px) {
    padding: 0 20px;
    height: 80px;

    .restrictedAccess {
      flex-direction: column;
      margin: auto 0;
    }

    svg {
      width: 24px;
    }
  }
`;

export const Profile = styled(Link)`
  display: flex;
  align-items: center;

  img {
    height: 56px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      font-size: 14px;
      color: ${({ theme }) => theme.COLORS.BLUE};
    }

    strong {
      font-size: 18px;
      color: ${({ theme }) => theme.COLORS.GRAY_300};
    }
  }

  @media(max-width: 767px) {
    img {
    height: 40px;
  }
  }
`;

export const Menu = styled.span`
  svg {
    font-size: 30px;
    cursor: pointer;

    &:hover {
      opacity: 70%;
    }

    &:first-child {
      margin-right: 20px;
    }
  }
`;

export const Back = styled.button`
  border: none;
  background: none;
  height: 30px;
  width: 30px;
  border: 0;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  position: absolute;
  top: calc(50% - 15px);
  left: 16px;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? "transparent" : theme.COLORS.BLUE};

    svg {
      color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_100 : theme.COLORS.WHITE};
    }
  }

  svg {
    height: 25px;
    width: 25px;
    margin: 0 2px 0 0;
    opacity: ${({ disabled }) => (disabled ? "30%" : "100%")};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_100 : theme.COLORS.BLUE};
  }

  @media (max-width: 767px) {
    left: 0;
  }
`;