import { BoxWrapper, Container, Line } from "./styles";
import theme from "../../styles/theme";

export default function CheckBoxGroup({
  list,
  form,
  handleForm,
  id,
  disabled,
}) {
  function handleChange(e) {
    if (form[id]) {
      Number(e.target.value) === form[id]
        ? handleForm({
            name: id,
            value: 0,
          })
        : handleForm({
            name: id,
            value: Number(e.target.value),
          });
    } else
      handleForm({
        name: id,
        value: Number(e.target.value),
      });
  }

  return (
    <Container>
      {list.map((item, index) => (
        <CheckBox
          key={index}
          info={item}
          selected={form[id]}
          handleChange={handleChange}
          disabled={disabled}
        />
      ))}

      <Line theme={theme} />
    </Container>
  );
}

function CheckBox({ info, selected, handleChange, disabled }) {
  const checked = selected === info.id;

  return (
    <BoxWrapper
      theme={theme}
      score={info.value}
      checked={checked}
      disabled={disabled}
    >
      <input
        type="checkbox"
        value={info.id}
        checked={checked}
        onChange={handleChange}
      />

      <label>{info.description}</label>
    </BoxWrapper>
  );
}
