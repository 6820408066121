import * as authApi from "../../services/authApi";
import useAsync from "../useAsync";
import useToken from "../useToken";

export default function useSignUp() {
  const token = useToken();

  const {
    loading: signUpLoading,
    error: signUpError,
    act: signUp,
  } = useAsync((data) => authApi.signUp(data, token), false);

  return {
    signUpLoading,
    signUpError,
    signUp,
  };
}
