import { useEffect, useState } from "react";

import { Container } from "./styles";
import theme from "../../styles/theme";
import loginUtils from "../../utils/login-utils";

export default function PasswordInstructions({ typingPassword, password }) {
  const [requirements, setRequirements] = useState({
    length: false,
    num: false,
    specialChar: false,
    upperCase: false,
    lowerCase: false,
  });

  useEffect(() => {
    loginUtils.validatePassword({
      password,
      requirements,
      setRequirements,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <Container
      typingPassword={typingPassword}
      theme={theme}
      requirements={requirements}
    >
      <p id="length">Mínimo de 8 caracteres</p>
      <p id="num">mínimo de 2 númeross</p>
      <p id="special-char">Mínimo de 1 carácter especial</p>
      <p id="upper-case">Mínimo de 1 letra maiúscula</p>
      <p id="lower-case">Mínimo de 1 letra minúscula</p>
    </Container>
  );
}
